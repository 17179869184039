import React, { ChangeEvent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { StyledToggle } from './styledToggle';

export interface IToggleProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
  $size?: 'sm' | 'default';
  id: string;
  ariaLabel: string;
  name: string;
}

const Toggle = ({
  onChange,
  checked = false,
  disabled,
  $size = 'default',
  id,
  ariaLabel,
  name,
}: IToggleProps): JSX.Element => (
  <FormGroup>
    <StyledToggle
      name={name}
      type="switch"
      role="switch"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      $size={$size}
      aria-checked={checked}
      id={id}
    />
    <Label aria-label={ariaLabel} htmlFor={id} />
  </FormGroup>
);

export default Toggle;
