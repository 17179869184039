import { defineMessages } from 'react-intl';

export default defineMessages({
  leadText: {
    id: 'pages.login.leadText',
    defaultMessage: `If you have purchased a protection plan from one of {link}, 
    login with the same phone number you used on their website or app.`,
  },
  leadTextEmailOnly: {
    id: 'pages.login.leadTextEmailOnly',
    defaultMessage: `If you have purchased a XCover Protection from one of {link}, login with the same email address you used on their website or app.{br} If you used your Apple ID to purchase XCover Protection or if you’re logging in for the first time, you need to find the email confirmation sent from XCover. Click the link in the email to activate your account.`,
  },
  forgotLink: {
    id: 'loginForm.forgotLink',
    defaultMessage: 'Forgot your password?',
  },
  sentConfirmation: {
    id: 'loginForm.sentConfirmation',
    defaultMessage: 'We’ve just sent through a verification {media} to {emailOrPhone}.',
  },
  sentInstructions: {
    id: 'loginForm.sentInstructions',
    defaultMessage: 'Click on the confirmation link in the {media} to access your account.',
  },
  passwordLabel: {
    id: 'loginForm.passwordLabel',
    defaultMessage: 'Password',
  },
  leadTextLinkText: {
    id: 'pages.login.leadTextLinkText',
    defaultMessage: 'our partners',
  },
  passwordErrorText: {
    id: 'pages.login.passwordErrorText',
    defaultMessage: 'Please enter your password',
  },
  invalidEmailAddressMessage: {
    id: 'loginForm.invalidEmailErrorMessage',
    defaultMessage:
      'There’s no account linked to that email address. Please enter the same email address you used when you purchased protection via one of <a href="/help#partner-logo-buttons">our partners</a>. </br></br>When you first visit XCover, or if you used an Apple device when you purchased XCover Protection, you will need to activate your account. <strong>Simply find the XCover confirmation email in your inbox and click the link to set up your XCover Account.</strong></br></br> If you can’t find any emails from us or need more help logging in, please check <a href="/help#xcover-login">these instructions</a>.',
  },
  invalidMobileNumberMessage: {
    id: 'loginForm.invalidMobileErrorMessage',
    defaultMessage:
      'There’s no account linked to that phone number. Please enter the same phone number used when you purchased protection via one of <a href="/help#partner-logo-buttons">our partners</a>.</br></br>When you first visit XCover you will need to activate your account. <strong>Simply find the XCover confirmation SMS and click the link to set up your XCover Account.</strong></br></br>If you can’t find any SMSs from XCover or you need more help logging in, please check <a href="/help#xcover-login">these instructions</a>.',
  },
  alreadyRegisteredMessage: {
    id: 'loginForm.alreadyRegisteredErrorMessage',
    defaultMessage: 'This account has already been created. Please log in.',
  },
});
